import React, { useEffect } from 'react';
import FullPageCarousel from '../Components/Carousel/FullPageCarousel';
import ImageCard from '../Components/ImageCard/ImageCard';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PageCss/HomePage.css';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import CenterStage from '../Components/CenterStage/CenterStage';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook


import imgHose from '../assets/hose.jpg';
import imgMetalHose from '../assets/metal-hose.jpg';
import imgIndustrialHose from '../assets/industrial-hose.jpg';
import HydraulicHoseLine from '../assets/Services/PreventativeMaintainance/PreventativeMaintainance/HydraulicHoseLine.jpg'
import OperateSystems from '../assets/Services/PreventativeMaintainance/PreventativeMaintainance/OperateSystems.jpg'
import imgHighPressureHose from '../assets/high-pressure-hose.jpg'
import qualitySticker from '../assets/QualityLogo.png';
import glob from '../assets/GlobLogo.png';
import hose from '../assets/HoseLogo.png';
import isoLogo from '../assets/ISO-Logo.png';
import imgCarton from '../assets/Hansa-Flex_Kartons_Shop.png';
import imgDigitilization from '../assets/DigilisationImg.jpg'
import imgLogistics from '../assets/LogisticsImg.jpg';
import imgTrainingCourse from '../assets/TrainingCourseImg.jpg';
import imgIree from '../assets/news-events/iree.jpg';
import imgGarry from '../assets/news-events/garry.jpg';
import imgGCC from '../assets/news-events/gcc.jpg';
import imgTree from '../assets/news-events/tree.jpg';
import imgBauma from '../assets/news-events/bauma.jpg';
import imgMachine from '../assets/machine.jpg';
import imgHighPressurePipe from '../assets/high-pressure-pipe.jpg';
import ContactBubble from '../Components/ContactBubble/ContactBubble';
import imgIFP from '../assets/IFP1.jpg';
import imgMHP from '../assets/MHP1.jpg';
import imgSHP from '../assets/SHP1.jpg';
import imgRapidmobile from '../../src/assets/Rapidmobile.jpg'

import Promoter from '../Components/Promoter/Promoter';
import FeatureCardCarousel from '../Components/FeaturesCardCarousal/FeatureCardCarousal'

import AOS from 'aos';
import 'aos/dist/aos.css';

import HoseManagement from '../assets/Services/HoseManagement.jpg'
import FluidServices from '../assets/Services/fluidService.jpg'
import MobileService from '../assets/Services/MobileService.jpg'
import HydraulicAcademy from '../assets/Services/HydraulicAcademy.jpg'
import CredentialsImg from '../assets/Services/Credentials.jpg'
import UAEPipefittings from "../assets/UAEPipefittings.jpg"
import circleCheck from '../assets/check-circle.svg'
import ImageDescription from '../Components/ImageDescription/ImageDescription';
import imgIndustries from '../assets/UAEWebsite.jpg'
import Horizontal from '../Components/Horizontal/Horizontal';
import imgADIPEC from '../assets/news-events/ADIPEC/banner3.jpeg'

function HomePage() {

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 320, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  const carouselItems = [
    {
      imgSrc: imgIFP,
      title: 'IFP - Insert Ferrule and Fittings Unit',
      description: 'Our state-of-the-art manufacturing facility is dedicated to crafting premium Insert & Ferrules products.',
      link: '/ifp'
    },
    {
      imgSrc: imgMHP,
      title: 'MHP - Metal Hose Production Unit',
      description: 'The journey of HANSA-FLEX India commenced with this unit initially focusing on serial hose production. Over time, our operations expanded',
      link: '/mhp'
    },
    {
      imgSrc: imgSHP,
      title: 'SHP - Serial Hose Production Unit',
      description: "Welcome to HANSA-FLEX India's Serial Hose Production. Our journey began humbly in 2018 with a small unit, which you can explore further.",
      link: '/shp'
    }
  ];


  const featuresData = [
    {
      title: "Extensive Spare Parts Inventory",
      description: "Maintaining a stock of 8,000+ spare parts and offering 10,000 more overnight to minimize customer downtime.",
      image: circleCheck
    },
    {
      title: "Technical Consultation Services",
      description: "Offering expert advice on system design, optimization, maintenance, and spare parts procurement for efficient, uninterrupted operations.",
      image: circleCheck
    },
    {
      title: "Global Accessibility",
      description: "With a global network of subsidiaries, HANSA-FLEX MIDDLE EAST ensures customers receive individualized, fast, and reliable service worldwide.",
      image: circleCheck
    },

    // Add more objects for additional FeaturesCard data
  ];

  //promoter content

  const promoterTitle = 'PLACE YOUR ORDER TODAY!';
  const promoterChecklistItems = [
    'The full HANSA‑FLEX product range with more than 80,000 articles',
    'Category-specific filter parameters enable quick navigation',
    'Please call us for availability'
  ];
  const promoterButtonLabels = ['CONTACT US', 'FIND OUT MORE !'];

  //**

  const contactBubbleData = {
    image: imgDigitilization,
    title: 'Digital services for straightforward product identification',
    description: 'Make the most of faster and more error-free data transfer using state-of-the-art scanner technology or EDI interfaces within a user-friendly application environment.',
    listItems: [
      'Online shop and My.HANSA-FLEX customer portal',
      'Scanner solutions to determine your product needs',
      'Master and material data transferred electronically'
    ],
    buttonName: "Learn more"
  }

  // hf tabs content
  const imageTextData = [
    {
      image: imgDigitilization,
      title: 'Digital services for straightforward product identification',
      description: 'Make the most of faster and more error-free data transfer using state-of-the-art scanner technology or EDI interfaces within a user-friendly application environment.',
      listItems: [
        'Online shop and My.HANSA-FLEX customer portal',
        'Scanner solutions to determine your product needs',
        'Master and material data transferred electronically'
      ],
      buttonName: "Learn more"
    },
    {
      image: imgLogistics,
      title: 'Logistics Services',
      description: 'Efficiently manage your supply chain with our comprehensive logistics solutions tailored to meet your specific needs.',
      listItems: [
        'Streamlined inventory management',
        'Optimized delivery scheduling',
        'Real-time tracking and reporting'
      ],
      buttonName: "Discover more"
    },
    {
      image: imgTrainingCourse,
      title: 'Training Courses',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at mi at dui lobortis sollicitudin. Curabitur finibus cursus hendrerit. Donec nulla lorem, ultricies quis ipsum vel, consequat aliquet eros. Proin magna libero, mattis sed feugiat nec, malesuada sed lacus. Nam posuere, purus a ornare tincidunt, sapien ligula lacinia eros, eu eleifend leo risus ut diam. Aenean volutpat ante ut pellentesque porta. Donec ut augue vestibulum, pretium massa et, accumsan magna..',

    },
  ];
  //  

  //image text data for heighlight points
  const highlightImgTxtData = {
    image: imgMachine,
    title: 'Leading system partner for hydraulics',
    description: 'HANSA‑FLEX offers a 360° fluid technology service for the planning, operation and optimisation of mobile and stationary hydraulic systems. An extensive product range naturally complements our comprehensive service portfolio – from highly efficient and flexible repair and installation, to complex engineering and consulting services – with our close-knit branch network, specialised manufacturing plants, digital service offerings and innovative logistics solutions tying it all together.',

    buttonName: "Learn more"
  }

  const highlightImgTxtData2 = {
    image: imgHighPressurePipe,
    title: 'The premium hose line for high pressures',
    description: "The HD 700 LL premium hose line from HANSA-FLEX represents a pinnacle of engineering excellence, meticulously crafted to excel even in the most demanding of conditions. This hose line is the result of extensive research, development, and testing, aimed at delivering unparalleled performance and durability.",

    buttonName: "Learn more"
  }
  //
  const googleReviewsLink = "https://www.google.com/search?q=HANSA-FLEX+Hydraulics+Middle+East+Electromechanical+Works+L.L.C.%0D%0A&sca_esv=5fc86f3f882bb04d&rlz=1C1GCEU_enIN1077IN1077&sxsrf=ADLYWIL4idna669YeckDiMTYuKLOHzGRjg%3A1717400752112&ei=sHRdZqG-BsnV4-EPnvyZmQc&ved=0ahUKEwih1pCu-L6GAxXJ6jgGHR5-JnMQ4dUDCBA&uact=5&oq=HANSA-FLEX+Hydraulics+Middle+East+Electromechanical+Works+L.L.C.%0D%0A&gs_lp=Egxnd3Mtd2l6LXNlcnAiQUhBTlNBLUZMRVggSHlkcmF1bGljcyBNaWRkbGUgRWFzdCBFbGVjdHJvbWVjaGFuaWNhbCBXb3JrcyBMLkwuQy4KMgcQIxiwAxgnMgcQIxiwAxgnMgcQIxiwAxgnMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHMgoQABiwAxjWBBhHSKwNUIgIWIgIcAN4AZABAJgBAKABAKoBALgBA8gBAPgBAvgBAZgCA6ACFZgDAIgGAZAGCpIHATOgBwA&sclient=gws-wiz-serp#lrd=0x3e5f6bdae190b3cf:0x98b7ff27fa3849e,1";
  return (
    <div>
      <FullPageCarousel />

      <SectionHeader
        title="What we offer"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHose} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX PROVIDES PREMIUM QUALITY HOSES" desc="The HANSA‑FLEX range includes hoses for hydraulics and pneumatics as well as for the transport of chemicals, food, air, water and abrasive media. In addition to standard hoses, we also supply suction and positive pressure hoses, suitable accessories and preassembled hose lines manufactured to your individual requirements. As a full-service provider, we also support our customers with installation, maintenance and inspection." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={UAEPipefittings} learnMoreLink="/pipe-fitting" title="PIPES AND SPECIAL FITTINGS" desc="The materials used for metal hoses make them extremely resistant to corrosion and abrasion. Their excellent temperature tolerance, strength under vacuum and outstandingly low permeability make metal hoses the preferred choice for transporting solid, liquid and gaseous substances under..." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={OperateSystems} learnMoreLink="/preventative-maintainance" title="PREVENTIVE MAINTAINANCE" desc="HANSA-FLEX supports its customers with a wide range of services relating to the preventative maintenance of stationery and mobile hydraulic systems. You can entrust the inspection, maintenance, repair and optimisation of your installation to the industrial assembly specialists of the HANSA-FLEX Group, or have a complete conversion operation carried out by us." />
          </Col>
          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgRapidmobile} learnMoreLink="/mobile-rapid-hydraulic-service" title="RAPID HYDRAULIC RESPONSE, READY FOR ACTION AT ANY TIME" desc="In unplanned downtimes, every minute counts. Optimally equipped with a mobile workshop on board and coordinated by an experienced service technician, the service fleet at HANSA FLEX provides rapid assistance in an..." />
          </Col>
          <Col sm={12} md={6} lg={6} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={HoseManagement} learnMoreLink="/Logisticssolutions" title="CUSTOMISED LOGISTICS SOLUTIONS FROM HANSA‑FLEX" desc="As a dealer and system partner, HANSA‑FLEX has the necessary knowledge to adjust your logistics optimally to the requirements of your company." />
          </Col>

        </Row>
      </Container>

      {/* <div>
        <SectionHeader
          title="Our Services"
        /> */}
      {/* 
        <Container className="custom-container">
          <Row> */}




      {/* </Row>
        </Container>


      </div> */}


      {/* <div>
        <SectionHeader
          title="Explore our units!"
          paragraph="We have our units in Pune, which provide extensive range of products!"
        />
        <ShowcaseCarousel carouselItems={carouselItems} descDisplay={isMobile ? 'none' : 'block'} />
      </div>
      <br /><br /><br /> */}

      <div className=''>
        <SectionHeader
          title="Premium Hydraulics & Pneumatics Solutions"
          subtitle="Select from more than 80,000 articles and always find the right product for your needs. We stock everything from A-Z."
        />
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            btnLabel1={"Find Out More !"}
            btnLabel1Link={"/hoses-and-hose-lines"}
            imageUrl={imgCarton}
          />
          // TODO: contact us button
        )}
      </div>


      <div className='div-gray'><br /><br /><br />
        {/* <SectionHeader
          title="News and Events" /> */}
        <Container className="custom-container">
          <Row>
            <a href="/adipec-2024" className='d-flex justify-content-center'>  {/* learnMoreLink={'/adipec-2024'} */}
              <Col sm={12} md={12} lg={10} data-aos="fade-up" ><ImageCard image={imgADIPEC}  title="HANSA-FLEX Unveils Hydraulic Innovations at ADIPEC 2024" desc={"Discover HANSA-FLEX’s specialized oil and gas solutions, from high-performance hoses to metal tubes, at stall 14582 in Abu Dhabi."} /></Col>
            </a>
          </Row>
        </Container><br /><br />
      </div>


      <div>
        <SectionHeader title={"Industries Served"} />
        <div className="home-imageContainer">
          {isMobile ? <>
            <img src={imgIndustries} alt="Description of the image" className='home-img' />

          </> : <>
            <img src={imgIndustries} alt="Description of the image" className='home-img' />

          </>}
        </div>
      </div>
      <br />
      <br />
      <br />


      {/* <div className="text-center div-white">
        <SectionHeader
          title={"Check out our reviews on google!"}
          paragraph={"Curious about what others have to say about us? Take a moment to explore our Google reviews and discover why our customers rave about their experiences with us. "} />
        <br />
        <Button variant="primary" href={googleReviewsLink} target="_blank" className="btn-google" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>View our reviews on Google</Button>
      </div> */}

      <br />
      <br />
      {/* <br /><br /> */}

      <div>
        <Horizontal />
      </div>
    </div>

  );
}

export default HomePage;
