import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'

import imgStage from '../../assets/Products/Catalogues/stage.jpg'
import TextAccordion from '../../Components/TextAccordion/TextAccordion'

const Catalogues = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const items = [
        {
            header: "Hose technology and metal hoses",
            description: "The HANSA‑FLEX catalogues hose assemblies, hose technology and metal hoses:",
            points: [
                {
                    text: "Hose technology catalogue",
                    pdfs: [
                        { language: "English", link: "PDF_folder/catalogues/Hose_technology_catalogue_2022.pdf" },
                        { language: "German", link: "/PDF_folder/catalogues/Schlauchtechnik-Katalog_2022.pdf" }
                    ]
                },
                {
                    text: "Metal Hoses Catalogue",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/catalogues/Metal_Hoses_Catalogue_2015.pdf" },
                        { language: "German", link: "/PDF_folder/catalogues/Metallschläuche-Katalog_2015.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Hydraulic components",
            points: [
                {
                    text: "Hydraulic components",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/catalogues/Hydraulic_components_catalogue_2016.pdf" },
                        { language: "German", link: "/PDF_folder/catalogues/Hydraulikkomponenten-Katalog_2016.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Hydraulic connection technology",
            points: [
                {
                    text: "Hydraulic connection technology",
                    pdfs: [
                        { language: "English part 1", link: "/PDF_folder/catalogues/Verbindungstechnik-Katalog_2024_EN_web_1-259.pdf" },
                        { language: "English part 2", link: "/PDF_folder/catalogues/Verbindungstechnik-Katalog_2024_EN_web_260-520.pdf" },
                        { language: "German  part 1", link: "/PDF_folder/catalogues/Verbindungstechnik-Katalog_2024_DE_web_1-259.pdf" },
                        { language: "German  part 2", link: "/PDF_folder/catalogues/Verbindungstechnik-Katalog_2024_DE_web_260-520.pdf" },
                    ]
                },
            ]
        },
        {
            header: "Sealing technology",
            points: [
                {
                    text: "Sealing technology",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/catalogues/Sealing_technology_catalogue_2016.pdf" },
                        { language: "German", link: "/PDF_folder/catalogues/Dichtungstechnik-Katalog_2016.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Pneumatics",
            points: [
                {
                    text: "Pneumatics",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/catalogues/Pneumatic_products_catalogue_2015.pdf" },
                        { language: "German", link: "/PDF_folder/catalogues/Pneumatik-Katalog_2015.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Industrial technology",
            points: [
                {
                    text: "Industrial technology",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/catalogues/Catalogue_3_Industrial_Technology.pdf" },
                        { language: "German", link: "/PDF_folder/catalogues/Katalog_Industrietechnik_2014.pdf" }
                    ]
                },
            ]
        },
    ]


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"PRODUCTS AT A GLANCE"}
                    paragraph={"All HANSA‑FLEX catalogues as PDF in German and English can be found on this page. You are also welcome to use our online shop as a digital listing of all products."}
                />
            </div>

            <div>
                <TextAccordion
                    title={"THE HANSA‑FLEX PRODUCT CATALOGUES"}
                    subtitle={"All catalogues available as PDF in German and English"}
                    items={items}
                />
            </div>

        </div>
    )
}


export default Catalogues